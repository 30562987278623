import * as React from 'react';
import _ from 'lodash';
import { Button, Table } from 'reactstrap';

import { Link } from 'gatsby';
import aboutIndexData from '../../data/about/index.json';
import {
  DefaultCollapsibleMarkdownSection,
  HashControlledCollapsibleSection,
  InformationLayout,
  Markdown,
} from '../../components';
import ContactSection from './ContactSection';

export const AboutIndexPageInternal = ({ mainMarkdown, header, contact, history, news, staff, join }: typeof aboutIndexData) => {
  return (
    <InformationLayout title={header} mainMarkdown={mainMarkdown} header={header}>
      <div className="child-spacing-y-3">
        <HashControlledCollapsibleSection {...contact}>
          <div className="child-spacing-y-3">
            <ContactSection {...contact.call}>
              {
                _.map(contact.call.phoneNumbers, ({ number }, i) => (
                  <div key={i}>{number}</div>
                ))
              }
            </ContactSection>

            <ContactSection {...contact.email}>
              <div>{contact.email.emailAddress}</div>
            </ContactSection>

            <ContactSection {...contact.visit}>
              <div>{contact.visit.addressLine1}</div>
              <div>{contact.visit.addressLine2}</div>
            </ContactSection>

            <ContactSection {...contact.hours}>
              <Table borderless size="sm" hover>
                <tbody>
                  {
                    _.map(contact.hours.hours, ({ hours, label }, i) => (
                      <tr key={i}>
                        <td className="fit">{label}</td>
                        <td>{hours}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </ContactSection>

            <ContactSection {...contact.lostOrStolenCard}>
              {
                _.map(contact.lostOrStolenCard.types, (type, i) => (
                  <div key={i}>{type.label} {type.phoneNumber}</div>
                ))
              }
            </ContactSection>
          </div>
        </HashControlledCollapsibleSection>

        <DefaultCollapsibleMarkdownSection {...history} />

        <HashControlledCollapsibleSection {...news}>
          <Markdown source={news.mainMarkdown} />

          <Button color="primary" tag={Link} to="/about/news-info">
            {news.buttonText}
          </Button>
        </HashControlledCollapsibleSection>

        <DefaultCollapsibleMarkdownSection {...staff} />

        <DefaultCollapsibleMarkdownSection {...join} />
      </div>
    </InformationLayout>
  );
};

export default () => <AboutIndexPageInternal {...aboutIndexData} />;
