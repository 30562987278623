import React, { ReactNode } from 'react';
import { Col, Row } from 'reactstrap';
import classnames from 'classnames';

interface Props {
  icon: string;
  label: ReactNode;
  children?: ReactNode;
}

const ContactSection = ({ icon, label, children }: Props) => {
  return (
    <Row className="text-center text-lg-left">
      <Col className="text-center" md={2}>
        <i className={classnames('fa fa-4x', icon)} />
      </Col>
      <Col md={10}>
        <h5>{label}</h5>
        {children}
      </Col>
    </Row>
  );
};

export default ContactSection;
